import React, { useContext, useState } from "react";
import "./Login.css";
import { AuthContext } from "../main_dashboard/Main";

const Login = () => {
    const [isLoggedIn, setIsLoggedIn] = useContext(AuthContext);
    const [userName, setUserName] = useState(null);
    const [password, setPassword] = useState(null);

    const setLogin = () => {
        if (userName === "Tanishka" && password === "Tanishka@123") {
            setIsLoggedIn(true);
        } else {
            alert("enter valid credentials!");
        }
    };

    return (
        <div className="my_card">
            <div className="Heading">
                <h1 className="">Login</h1>
            </div>
            <div className="card_1">
                <div>
                    <div class="Wrapper">
                        <p className="my_p">Sign in to start your session</p>
                        <label for="inputEmail4" class="form-label"></label>
                        <input
                            type="text"
                            class="form-control my_css"
                            id="userName"
                            placeholder="user Name"
                            onChange={(e) => {
                                setUserName(e.target.value);
                            }}
                        />
                    </div>
                    <div class="">
                        <label for="inputPassword4" class="form-label"></label>
                        <input
                            type="password"
                            class="form-control"
                            id="inputPassword4"
                            placeholder="Password"
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                        />
                    </div>
                    <div class="flex my_css">
                        <div class="">
                            <button
                                type="submit"
                                class="btn btn-primary"
                                onClick={setLogin}
                            >
                                Login
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
