import React, { Component } from "react";
import { Row, Col, Modal, Card, Table as Tbl } from "react-bootstrap";

//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { toast } from "react-toastify";

import moment from "moment";

import { Button, TextField } from "@material-ui/core";

// font awasome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";

import qrCodeImg from "./assets/qrcode.jpeg";
//API handling components
import { API_URL } from "./../../global";
const axios = require("axios");

export default class BillHistory extends Component {
    constructor(props) {
        super();

        this.state = {
            billList: null,
            showUpdateModel: false,
            activeBillId: null,
            activePaid: 0,
            balance: 0,
            date: null,
            paid: 0,
            partyId: null,
            pname: null,
            address: null,
            mobileNo: null,
            total: 0,
            gst: 0,
            id:1,
            discount: null,

            itemsList: [],
            isLoadingItems: false,
        };
    }

    fetchBillList = () => {
        let url = API_URL;
        const query = `SELECT gb.*, p.name as pname, p.address as address, p.mobile as mobileNo FROM gstBill as gb inner join party as p where gb.partyId = p.id AND gb.status=1 ORDER BY gb.id DESC;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("bill: ", res.data);
                this.setState({ billList: res.data });

                // init data table
                this.initializeDataTable();
            })
            .catch((err) => {
                console.log("deliveryMemo list fetch error: ", err);
            });
    };

    fetchBillItemList = () => {
        let url = API_URL;
        const query = `SELECT bl.* FROM billList as bl inner join gstBill as gb on bl.billType=gb.id where bl.billId= ${this.state.activeBillId}`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        console.log(query);
        axios
            .post(url, data)
            .then((res) => {
                console.log("bill list data: ", res.data);
                this.setState({ itemsList: res.data });

                // init data table
                this.initializeDataTable();
            })
            .catch((err) => {
                console.log("bill list fetch error: ", err);
            });
    };

    deleteRecord(id) {
        let url = API_URL;
        const query = `UPDATE gstBill SET status = 0  WHERE id=${id};`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("deleted status data: ", res.data);
                toast.success("item deleted successfully");
                toast.success("Record deleted successfully");
                this.fetchBillList();
            })
            .catch((err) => {
                console.log("record delete error: ", err);
                toast.error("Failed to delete record");
            });
    }

    initializeDataTable() {
        const title = "GST bill history-" + moment().format("DD/MM/YYYY");
        $(document).ready(function () {
            $("#billList").DataTable({
                destroy: true,
                dom:
                    "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
                    "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
                    "<'row'<'col-sm-12' tr>>" +
                    "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
                buttons: [
                    {
                        extend: "csv",
                        title,
                        download: "open",
                        exportOptions: {
                            columns: [0, 1, 2, 3, 4, 5, 6],
                        },
                    },
                    {
                        extend: "print",
                        title,
                        messageTop: `<h4 style='text-align:center'>${title}</h4>`,
                        download: "open",
                        exportOptions: {
                            columns: [0, 1, 2, 3, 4, 5, 6],
                        },
                    },
                ],
            });
        });
    }

    initializeData() {
        this.fetchBillList();
    }
    componentDidMount() {
        this.initializeData();
    }

    handleUpdateSubmit(e) {
        let url = API_URL;
        const query = `UPDATE gstBill SET balance = balance - ${this.state.activePaid}, paid = paid + ${this.state.activePaid} WHERE id=${this.state.activeBillId};`;

        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(url, data)
            .then((res) => {
                toast.success("Bill details updated successfully");
                this.fetchBillList();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    renderMemoList = () => {
        if (this.state.billList == null) return null;

        // else
        return this.state.billList.map((bill) => {
            return (
                <tr align="center">
                    <td>{bill.id}</td>
                    <td>{bill.pname}</td>
                    <td>{bill.total}</td>
                    <td>{bill.paid}</td>
                    <td>{bill.balance}</td>
                    <td>{moment(bill.date).format("DD/MM/YYYY")}</td>
                    <td className="d-flex justify-content-center">
                        &nbsp;
                        <Button
                            className="mt-2"
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                this.setState(
                                    {
                                        activeBillId: bill.id,
                                        showDisplayBillModal: true,
                                        aadharCard: bill.aadharCard,
                                        code: bill.code,
                                        date: bill.date,
                                        pname: bill.pname,
                                        address: bill.address,
                                        mobileNo: bill.mobileNo,
                                        total: bill.total,
                                        gst: bill.gst,
                                        discount: bill.adjustment,
                                    },
                                    this.fetchBillItemList
                                );
                            }}
                        >
                            <FontAwesomeIcon icon={faEye} />
                        </Button>
                        <Button
                            className="mt-2"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                this.setState({
                                    activeBillId: bill.id,
                                    showUpdateModel: true,
                                });
                            }}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                        <Button
                            className="mt-2"
                            color="danger"
                            variant="contained"
                            onClick={(e) => {
                                if (window.confirm("Delete the item?")) {
                                    this.deleteRecord(bill.id);
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </td>
                </tr>
            );
        });
    };

    renderUpdateModal = () => {
        return (
            <Modal
                show={this.state.showUpdateModel}
                onHide={(e) => this.setState({ showUpdateModel: false })}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update bill
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form noValidate autoComplete="off">
                        <div className="mt-3">
                            <Row>
                                <Col xs={12}>
                                    <TextField
                                        id="pending"
                                        label="Paid"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue=""
                                        value={this.state.activePaid}
                                        onChange={(e) =>
                                            this.setState({
                                                activePaid: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                        </div>

                        <div className="mt-2 mr-1">
                            <Button
                                style={{ float: "right" }}
                                onClick={(e) => {
                                    this.setState({
                                        showUpdateModel: false,
                                    });
                                    this.handleUpdateSubmit(e);
                                }}
                                variant="contained"
                                color="primary"
                            >
                                Update
                            </Button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        );
    };

    renderDisplayBillModal = () => {
        return (
            <Modal
                show={this.state.showDisplayBillModal}
                onHide={(e) => this.setState({ showDisplayBillModal: false })}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Bill details
                    </Modal.Title>
                </Modal.Header>
                <div className="mt-1 measure">
                    <Row>
                        <Col className="mx-auto">
                            <Card className="p-0">
                                <Card.Header>
                                    <Row>
                                        <Col className="border-right pb-5">
                                            <Card.Title className="text-start pb-0 mb-0">
                                                <b class="h3">
                                                    Tanishka Motors
                                                </b>
                                            </Card.Title>
                                        </Col>
                                        <Col>
                                            <p className="text-center pb-0 mb-0">
                                                Bheda Hospital chowk Karad,
                                                Orekal building,
                                                <br /> Shop No. 2, Karad, Dist:
                                                Satara
                                            </p>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col>
                                            <p className="text-center">
                                                Customer Care No. 9960565357 /
                                                9611227577
                                            </p>
                                        </Col>
                                        <Col>
                                            <p className="text-center">
                                                email ID:
                                                Tanishkamotors02@gmail.com
                                            </p>
                                        </Col>
                                    </Row>

                                    <hr />
                                    <span
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <p>
                                            Invoice No.{" "}
                                            <b>{this.state.activeBillId}</b>
                                        </p>
                                        <p>
                                            Date{" "}
                                            <b>
                                                {moment(new Date()).format(
                                                    "D/M/YYYY"
                                                )}
                                            </b>
                                        </p>
                                    </span>

                                    <hr />
                                </Card.Header>
                                <Card.Body className="pb-3 mb-0">
                                    <Row>
                                        <Col md={6}>
                                            <h6
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                Party name:{" "}
                                                <b>{this.state.pname}</b>
                                            </h6>
                                        </Col>

                                        <Col md={6}>
                                            <h6
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                Address:{" "}
                                                <b>{this.state.address}</b>
                                            </h6>
                                        </Col>

                                        <Col md={6}>
                                            <h6
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                GSTIN: <b>{this.state.gstin}</b>
                                            </h6>
                                        </Col>
                                        <Col md={6}>
                                            <h6
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                Mobile No:{" "}
                                                <b>{this.state.mobileNo}</b>
                                            </h6>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Body className="m-0 pt-0">
                                    {/* Order overview */}
                                    <Tbl striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                <th>Sr.No.</th>
                                                <th colSpan="3">Product</th>
                                                <th>Quantity</th>
                                                <th>Rate</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        {this.state.itemsList.length > 0 ? (
                                            <tbody>
                                                {this.state.itemsList.map(
                                                    (item, id) => {
                                                        return (
                                                            // <tr key={"" + item.particularValue.title}>
                                                            //   <td>{item.particularValue.title} </td>
                                                            <tr
                                                            key={id}
                                                            >
                                                                 <td>{id + 1} {" "}
                                                                </td>
                                                                <td colSpan="3">
                                                                    {
                                                                        item.particular
                                                                    }{" "}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.quantity
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {item.rate}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.amount
                                                                    }
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                               
                                                <>
                                                        {/* <tr>
                                                            <td colSpan="3">
                                                                Total amount
                                                                before tax
                                                            </td>
                                                            <td colSpan="2">
                                                                {
                                                                    this.state
                                                                        .total
                                                                }
                                                            </td>
                                                        </tr> */}
                                                        <tr>
                                                            <td colSpan="6">
                                                                Output C GST 2.5%
                                                            </td>
                                                            <td colSpan="1">
                                                                {
                                                                    this.state
                                                                        .gst / 2
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="6">
                                                                Output S GST 2.5%
                                                            </td>
                                                            <td colSpan="1">
                                                                {
                                                                    this.state
                                                                        .gst / 2
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                                <td></td>
                                                                <td colSpan="3"></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td>{this.state.gst}</td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td colSpan="3"></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        <tr>
                                                            
                                                            <td colSpan="6" className="text-center">
                                                                <b>Total Amount</b>
                                                            </td>
                                                            <td colSpan="1">
                                                                <b>
                                                                {
                                                                        this.state.total
                                                                    }
                                                                </b>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            
                                                            <td colSpan="6" className="text-center">
                                                                <b>Discount Amount</b>
                                                            </td>
                                                            <td colSpan="1">
                                                                <b>
                                                                {
                                                                        this.state.discount
                                                                    }
                                                                </b>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            
                                                            <td colSpan="6" className="text-center">
                                                                <b>Grand Total</b>
                                                            </td>
                                                            <td colSpan="1">
                                                                <b>
                                                                {
                                                                        this.state.total - this.state.discount
                                                                    }
                                                                </b>
                                                            </td>
                                                        </tr>
                                                        {/* <tr>
                                                            
                                                            <td colSpan="7" className="text-center">
                                                                <b>Discount Amount</b>
                                                            </td>
                                                            <td colSpan="1">
                                                                <b>
                                                                    {
                                                                        this.state.total - this.state.discount
                                                                    }
                                                                </b>
                                                            </td>
                                                        </tr> */}
                                                        {/* <tr>
                                                            <td colSpan="7">
                                                                <b>Amt Chargeable (inwords)<br /><br />
                                                                    ************</b>
                                                                <span style={{ float: 'right', marginTop: '-3em' }}>E & O.E
                                                                </span>
                                                                <b></b>
                                                            </td>
                                                        </tr> */}
                                                        <tr>
                                                            <td colSpan="1" >
                                                                <b>HSN/SAC</b>
                                                            </td>
                                                            <td colSpan="1">
                                                                <b>Taxable Value</b>
                                                            </td>
                                                            <td colSpan="2">
                                                                <b>Central Tax</b>
                                                            </td>
                                                            <td colSpan="2">
                                                                <b>State tax</b>
                                                            </td>
                                                            <td colSpan="1">
                                                                <b>Total Tax Amount</b>
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <td colSpan="1" >
                                                                <b></b>
                                                            </td>
                                                            <td colSpan="1">
                                                                <b></b>
                                                            </td>
                                                            <td colSpan="1">
                                                                <b>Rate</b>
                                                            </td>
                                                            <td colSpan="1">
                                                                <b>Amount</b>
                                                            </td>
                                                            <td colSpan="1">
                                                                <b>Rate</b>
                                                            </td>
                                                            <td colSpan="1">
                                                                <b>Amount</b>
                                                            </td>
                                                            <td colSpan="1">
                                                                <b></b>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="1" >
                                                                <b></b>
                                                            </td>
                                                            <td colSpan="1">
                                                                <b>{this.state.total - this.state.gst}</b>
                                                            </td>
                                                            <td colSpan="1">
                                                                <b>2.5</b>
                                                            </td>
                                                            <td colSpan="1">
                                                                <b>{this.state.gst / 2}</b>
                                                            </td>
                                                            <td colSpan="1">
                                                                <b>2.5</b>
                                                            </td>
                                                            <td colSpan="1">
                                                                <b>{this.state.gst / 2}</b>
                                                            </td>
                                                            <td colSpan="1">
                                                                {(this.state.gst)}/-
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="1" >

                                                            </td>
                                                            <td colSpan="1">
                                                                <b></b>
                                                            </td>
                                                            <td colSpan="1">

                                                            </td>
                                                            <td colSpan="1">

                                                            </td>
                                                            <td colSpan="1">

                                                            </td>
                                                            <td colSpan="1">

                                                            </td>
                                                            <td colSpan="1">

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="1" >
                                                                <b></b>
                                                            </td>
                                                            <td colSpan="1">
                                                                <b>{this.state.total - this.state.gst}</b>
                                                            </td>
                                                            <td colSpan="1">
                                                                <b>2.5</b>
                                                            </td>
                                                            <td colSpan="1">
                                                                <b>{this.state.gst / 2}</b>
                                                            </td>
                                                            <td colSpan="1">
                                                                <b>2.5</b>
                                                            </td>
                                                            <td colSpan="1">
                                                                <b>{this.state.gst / 2}</b>
                                                            </td>
                                                            <td colSpan="1">
                                                                {(this.state.gst)}/-
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                    <td colSpan="7">Tax Amt (inwords)<br />INR Three Thousand Eight Hundred Twenty Five Only.<br /><br />
                                                        This is Computer Generated Invoice No Signature Required<p style={{ float: 'right' }}>Authorizrd Signature</p></td>
                                                </tr>
                                                        {/* <tr>
                                                            <td colSpan="3">
                                                                IGST 5%
                                                            </td>
                                                            <td colSpan="2">
                                                                {
                                                                    this.state
                                                                        .igst
                                                                }
                                                            </td>
                                                        </tr> */}
                                                    </>
                                            </tbody>
                                        ) : (
                                            <tbody>
                                                <tr>
                                                    <td colSpan="7">
                                                        No items added
                                                    </td>
                                                </tr>
                                            </tbody>
                                        )}
                                    </Tbl>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <p className="text-center" style={{marginTop:'1em',fontSize:'10px'}}>developed by 5TechG Lab | M:7028828831/9172227004</p>

                </div>
            </Modal>
        );
    };

    render() {
        return (
            <div>
                <Row>
                    <Col
                        md="12"
                        className="m-0 p-1 measure1"
                        style={{ minHeight: "85vh" }}
                    >
                        <div>
                            <table
                                id="billList"
                                class="display"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr align="center">
                                        <th align="center">Bill Id</th>
                                        <th align="center">Party Name</th>
                                        <th align="center">Total</th>
                                        <th align="center">Paid</th>
                                        <th align="center">Balance</th>
                                        <th align="center">Date</th>
                                        <th align="center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>{this.renderMemoList()}</tbody>
                            </table>
                            {this.renderUpdateModal()}
                            {this.renderDisplayBillModal()}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}
